import $ from 'jquery';

import flux from 'core/flux';
import ui from 'core/ui';

import AjaxUtil from 'optly/utils/ajax';
import handleAjaxError from 'optly/utils/handle_ajax_error';

import actionTypes from './action_types';
import getters from './getters';

/**
 * Dispatch to the proper stores to load the account data structure
 * @param {object} accountInfo
 */
export function loadAccountInfo(accountInfo) {
  flux.dispatch(actionTypes.ACCOUNT_INFO_LOADED, {
    accountInfo,
  });
  return accountInfo;
}

/**
 * Async fetch the account info
 */
export function fetchAccountInfo() {
  /* eslint-disable fetch/no-jquery */
  return $.ajax({
    url: '/account/info',
    type: 'GET',
  }).then(loadAccountInfo);
  /* eslint-enable fetch/no-jquery */
}

export function maybeShowFreeTrialNotification(currentProject) {
  const accountInfo = flux.evaluate(getters.accountInfo);
  const isFirstDay = () => {
    const today = new Date().toISOString().slice(0, 10);
    const accountCreated = new Date(currentProject.get('created'))
      .toISOString()
      .slice(0, 10);

    return today === accountCreated;
  };
  const getGreetingMessage = () => {
    const isMainProject =
      currentProject.get('id') === accountInfo.get('accountId');

    return isMainProject && isFirstDay() ? '' : tr('Welcome back! ');
  };
  const freeTrialMessage = () => {
    let msg = null;
    if (accountInfo.get('trialDaysLeft') > 0) {
      msg = tr.pluralize(
        'You have <span>1 day left</span> on your free trial. ',
        'You have <span>{0} days left</span> on your free trial. ',
        accountInfo.get('trialDaysLeft'),
      );
    } else {
      msg = tr('Your free trial has ended and experiments have been paused. ');
    }

    return msg;
  };
  if (accountInfo.get('trialDaysLeft') !== null) {
    // call in setTimeout to give the main region component time to settle and to allow addNewLoginHandler to happen in components
    setTimeout(() => {
      ui.showPersistentNotification({
        message: `<div class="flex--1 free-trial pop-up">
  ${getGreetingMessage()}${freeTrialMessage()}
  <a href="https://www.optimizely.com/plans">${tr('Upgrade now')}</a></div>`,
        type: accountInfo.get('trialDaysLeft') > 0 ? null : 'error',
        id: 'free-trial-status-notification',
        safeHTML: true,
      });
    }, 2000);
  }
}

export default {
  fetchAccountInfo,
  maybeShowFreeTrialNotification,
  loadAccountInfo,
};
